import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-copy-url',
  templateUrl: './copy-url.component.html',
  styleUrls: ['./copy-url.component.scss']
})
export class CopyURLComponent implements OnInit, AfterViewInit{

  url = ''
  copied=false

  constructor(private route: ActivatedRoute) { 
    this.route.params.subscribe(p => {
      this.url = atob(p.url)

    })

  }

  ngAfterViewInit() {
  }



  ngOnInit() {

  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0,1000);

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  copy2Clipboard(str : string) {
   
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(str)
    } else {
      navigator.clipboard.writeText(str)
    }
  }

  copyURL() {
    console.log(`URL: ${this.url}`)
    this.copy2Clipboard(this.url)
    this.copied = true
  }

}
