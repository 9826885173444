import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureComponent } from './signature/signature.component';
import { SignaturePadModule } from '@ng-plus/signature-pad'
import { RRFirebaseService } from './rrfirebase.service';
import { OTPRequestComponent } from './otprequest/otprequest.component';
import { FormsModule } from '@angular/forms';
import { SimpleFormComponent } from './simple-form/simple-form.component';
import { CopyURLComponent } from './copy-url/copy-url.component';


@NgModule({
  declarations: [SignatureComponent, OTPRequestComponent, SimpleFormComponent, CopyURLComponent],
  imports: [
    CommonModule,
    FormsModule,
    SignaturePadModule
  ],
  exports: [SignatureComponent, OTPRequestComponent,SimpleFormComponent]

})
export class RrcoreModule { }
