import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RRFirebaseService } from '../rrfirebase.service';
import { RRLib } from '../rrlib.service';


@Component({
  selector: 'app-otprequest',
  templateUrl: './otprequest.component.html',
  styleUrls: ['./otprequest.component.scss']
})
export class OTPRequestComponent implements OnInit {
  @Input() client: string
  @Input() terrid: string
  @Input() mdid: string
  @Input() email = ''
  @Input() mobile = ''

  @Output() validOTP = new EventEmitter<string>()
  @Output() invalidOTP = new EventEmitter()

  otpSent = false
  // email = ''
  // mobile = ''
  oTPValue = ''
  oTPEntered = ''
  oTPError = false

  constructor(private ds: RRFirebaseService) {

  }

  ngOnInit() {
    this.ds.login(`sa@${this.client}.idx`, 'password')
  }

  checkOTP() {
    if( this.oTPEntered == this.oTPValue) {
      this.validOTP.emit(this.oTPEntered)
      this.oTPError = false
    } else {
      this.oTPError = true
      this.invalidOTP.emit()
    }
  }

  sendOTP(channel: string) {
    this.otpSent = true
    this.oTPError = false
    this.oTPValue = (Math.random() * 1000000 + 100000).toString().substr(0, 4)
    this.ds.fbSetValue(`${this.client}/otp_request/${RRLib.CYCLE}/${RRLib.TODAY}/${this.terrid}/${this.mdid}`, { otp: this.oTPValue, channel: channel, email: this.email, mobile: this.mobile })

  }

  resendOTP() {
    this.ds.fbRemoveValue(`${this.client}/otp_request/${RRLib.CYCLE}/${RRLib.TODAY}/${this.terrid}/${this.mdid}`)
    this.oTPError = false
    this.otpSent = false
    this.oTPEntered = ''
  }
}
