import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CopyURLComponent } from './rrcore/copy-url/copy-url.component';



const routes: Routes = [
  { path : '', redirectTo: 'idoxsui', pathMatch: 'full'},
  { path : 'consent', loadChildren: "./consent/consent.module#ConsentModule" },
  { path : 'idoxsui', loadChildren: "./idoxs-ui/idoxs-ui.module#IDoXsUIModule" },
  { path : 'rd', loadChildren: "./remote-detailer/remote-detailer.module#RemoteDetailerModule" },
  { path : 'reports', loadChildren: "./reports/reports.module#ReportsModule" },
  { path : 'si', loadChildren: "./sample-inventory/sample-inventory.module#SampleInventoryModule" },
  { path : 'cl/:url', component:CopyURLComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
