import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

const _today = new Date();

export const CLIENT_NAMES = {
  'PIMS-BAYER' : 'Bayer Consumer Health',
  'E-FIRMA-PH' : 'A. Menarini Philippines'
}

@Injectable({
  providedIn: 'root'
})
export class RRLib {

  static TODAY =  _today.getFullYear() + "-" + RRLib.pad(_today.getMonth() + 1) + "-" + RRLib.pad(_today.getDate())
  static CYCLE =  _today.getFullYear() + "-" + RRLib.pad(_today.getMonth() + 1)

  static OTPSignature = '10,10,20,10/20,10,20,20/20,20,10,20/10,20,10,10/30,10,40,10/35,10,35,20/50,10,50,20/50,10,60,10/60,10,60,15/60,15,50,15'

  // static TODAY = '2020-12-27'
  // static CYCLE = '2020-01' 

  /***
 * @description add padding to a number
 * @param {type} number
 * @param {type} width_of_pad @default 2
 * @param {type} what_to_pad @default 0
 * @returns {String}
 */
  static pad(_number, width_of_pad = 2, what_to_pad = '0') {
    // what_to_pad = what_to_pad || '0';
    // width_of_pad = width_of_pad || 2;
    const number = _number + '';
    return number.length >= width_of_pad ? number : new Array(width_of_pad - number.length + 1).join(what_to_pad) + number;
  }


  static strToDate(sDate : string) {
    return new Date(sDate.replace(' +0800','').replace(' ','T') + '-GMT+800')
  }

  static dateToLongString(date?: Date, format?: string): string {

    if (date == null) {
      date = new Date();
    }

    const ds = (new DatePipe('EN-US')).transform(date, format || `yyyy-MM-dd HH:mm:ss ZZZ`);
    return ds;
  }

  static dateToday(): string {
    return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd');
    // return '2018-04-27'
  }

  static cycleToday(): string {
    return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM');
  }

  static downloadObjectAsFile(object: any, filename: string) {
    var sJson = JSON.stringify(object);
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  static nextCycle() : string {

    const stDate = new Date( `${RRLib.CYCLE}-01` )

    stDate.setDate( stDate.getDate() + 31 )

    return (new DatePipe('EN-US')).transform( stDate, 'yyyy-MM');

  }


  

  constructor() {
  }

  static sort = (a, b, c?) => {return c ? (a[c] > b[c] ? 1 : a[c] < b[c] ? -1 : 0) : (a > b ? 1 : a < b ? -1 : 0)}
  static sortDesc = (a, b, c?) => {return c ? (a[c] > b[c] ? -1 : a[c] < b[c] ? 1 : 0) : (a > b ? -1 : a < b ? 1 : 0)}

  flatten(data: any, keys: string[]) {

    var result = {};

    function recurse(cur: any, level) {

      if (level < keys.length) {

        const k = keys[level].replace('{', '').replace('}', '');

        result[k] = cur.key;

        cur.forEach(function (snap): boolean {
          //console.log(snap.key);
          result[snap.key] = snap.val();
          if (snap.child(snap.key).hasChildren()) {
            recurse(snap, level + 1);
          }
          return false;
        })

      }
    }

    if (typeof data.val() == 'object') {

      recurse(data, 0);
    } else {
      const k = keys[0].replace('{', '').replace('}', '');

      if (keys.length > 1) {

        result[k] = data.key;
        const k2 = keys[1].replace('{', '').replace('}', '');
        result[k2] = data.val();

      } else {
        result[k] = data.val();
      }
    }

    return result;
  }

  static hashCode(str) { // java String#hashCode
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    } else {
      return 0;
    }
  }

  static hashCode8(str) { // java String#hashCode
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 7) - hash);
      }
      return hash;
    } else {
      return 0;
    }
  }

  static intToRGB(i) {
    var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }

  static colorFromString(str: string): string {
    switch (str) {
      case "Reached": return 'gold'
      case "Unreached": return 'darkred'
      case "Called To Target": return 'green'

      default:
        return '#' + RRLib.intToRGB(RRLib.hashCode(str));
    }
  }

  static intToCode(i) {
    var c = (i & 0xFFFFFFFF)
      .toString(16)
      .toUpperCase();

    return "0000000".substring(0, 8 - c.length) + c;
  }

  static codeFromString(str: string): string {
    return RRLib.intToCode(RRLib.hashCode8(str));
  }


}
