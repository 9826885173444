import { Component, OnInit,ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RRFirebaseService } from 'src/app/rrcore/rrfirebase.service'; 
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-simple-form',
  templateUrl: './simple-form.component.html',
  styleUrls: ['./simple-form.component.scss']
})
export class SimpleFormComponent implements OnInit {
  @Input() formName;
  @Input() user;  
  objectKeys = Object.keys;
  token: any;
  client: string;
  territory_id: string;
  mdid: string;
  mdname: string;
  date: string
  dmname: string;
  dmid: string
  dmsigned: string
  repsigned: string
  repname: string;
  dateToday: string;
  count:any
  myDate = new Date();
  signed: string
  numberValue = ''
  score : any
  totalcount=0
  jvAnswered={}
  questionList= {}

  jvTable={}

  jvAnsweredObject ={}
  constructor(private ds: RRFirebaseService,private datePipe: DatePipe, private route: ActivatedRoute,) { 

    // this.count = Object.keys(this.jvTable['headers']).length - 1
    this.dateToday = this.datePipe.transform(this.myDate, 'MMMM d, y');

    this.route.params.subscribe(p => {
      this.token = p.token
      let [client, terrid, mdid, date] = atob(p.token).split('/')
      this.client = client
      this.territory_id = terrid
      this.mdid = mdid
      this.date = date 
      this.ds.login(`sa@${client}.idx`, 'password').then(() => {

        this.ds.fbObserveValue(`${this.client}/remote_detail/${date.substr(0, 7)}/${this.territory_id}/${date}/${this.mdid}`, (snap) => {

          const d = snap.val()
          this.mdname = d.mdname
          this.dmname = d.dmname
          this.dmid = d.dmid
          this.repname = d.repname
          this.repsigned = d.repsigned
          this.dmsigned = d.dmsigned 
          if(this.user=='detailer' || this.dmsigned)
          {
 
            this.ds.fbObserveValue(`${this.client}/simple-form/${this.formName}/joint-visit-lists/${this.date.substr(0, 7)}/${this.dmid}/${this.date}/${this.territory_id}/${this.mdid}/`, (snap) => {
              this.jvAnswered = snap.val()

            })
          }
  
        }) 
      })
    })


  }

  ngOnInit() { 

       this.ds.getValue(`${this.client}/simple-form/${this.formName}`).then(res=>{
         console.log(res)
        this.questionList = res['question-list']
        this.jvTable = res['jv-table']  
       }) 

  }

  onKey(event: any,id,type) { // without type info 
    //alert(event.target.id);    
    if(event.target.value != '')
    {
      var splitId = id.split('-');  
      var setKey= splitId[0]
      var questionKey = splitId[1]
      var answerKey = splitId[2] 
      var startObj = this.jvAnsweredObject['sets'] || {}
      var setObj = startObj[setKey] || {}
      var questionObj = setObj[questionKey]|| {} 
      var valueObj = questionObj[answerKey] || ''
      valueObj = event.target.value
      questionObj[answerKey] = valueObj
      setObj[questionKey] = questionObj
      startObj[setKey] = setObj
      this.jvAnsweredObject['sets'] = startObj 
    
    }
    var checkIfBlank=0
   
    if(type == "score" && event.target.value >100) 
    {
      checkIfBlank = 1
      event.target.value = ""
      alert("Greater that 100 is not accepted.")
      this.numberValue=''
      delete this.jvAnsweredObject['sets'][setKey][questionKey][answerKey]
    } 
     if(type == "score" && event.target.value == '' && checkIfBlank == 0) 
    {
      alert("Please enter score") 
    } 

    if(this.jvTable['rowTotalField'])
    {
    
      this.getTotalCount()

    }
   }
   getTotalCount()
   {
    this.totalcount=0
    this.score=0
    Object.keys(this.jvAnsweredObject['sets']).forEach(key1 =>{
      Object.keys(this.jvAnsweredObject['sets'][key1]).forEach(key2 =>{
        this.totalcount = this.totalcount + (this.jvAnsweredObject['sets'][key1][key2]['A4'] ? 1:0)
        this.score = this.score + (this.jvAnsweredObject['sets'][key1][key2]['A4'] ? parseInt(this.jvAnsweredObject['sets'][key1][key2]['A4']):0)

     }); 
    }); 
    this.score = (this.score/this.totalcount).toFixed(2) 
    var totalStr =  this.jvAnsweredObject['total']||'' 
    totalStr = this.score  
    this.jvAnsweredObject['total']=totalStr
    
   }
 numberOnly(event: any,id): boolean {
  
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
 
  else
  {
    return true;

  }

}
 

}
