import { Component, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-signature',
  template: '<ng-signature-pad doneButtonText="Submit" clearButtonText="Clear" doneButtonClass="btn btn-lg btn-primary  sigButton " clearButtonClass="btn btn-lg btn-secondary sigButton" [(points)]="signature" (done)="saveSignature($event)"></ng-signature-pad>',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {

  @Input() sign: any;
  @Output('onSigned') onSigned = new EventEmitter<string>()

  cord = []
  signature = []

  ngAfterViewInit() {

  }

  saveSignature(png) {

    const segs = this.signature
    const nrows = []

    segs.forEach(seg => {
      const points = seg.points
      console.log(points)
      do {
        const p1 = points.shift()
        const p2 = points[0]

        // const [x,y] = points.splice(0,2)
        // const [dx, dy] = points.slice(0,3)

        if (p2) {
          nrows.push(`${Math.round(p1.x)},${Math.round(p1.y)},${Math.round(p2.x)},${Math.round(p2.y)}`)
        }

        // console.log(`${x},${y},${dx | x},${dy | y}`)

      } while (points.length > 0)
    })

    // const sign = this.signature.map(seg => {
    //   return seg.points.map(pt => `${Math.round(pt.x)},${Math.round(pt.y)}`).join(',')
    // }).join('/')

    const sign = nrows.join('/')

    console.log(sign)
    this.onSigned.emit(sign)
  }

}