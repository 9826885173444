import { Injectable } from '@angular/core';
import * as firebase from 'firebase'
import { firebaseConfig } from "../../environments/firebaseConfig";
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';


firebase.initializeApp(firebaseConfig)
const keys = {
  'cocoi-msites': 'fIuL3gARUYMCtbrqOX7IVE9Rcj619QsS7TLurBJG',

}

@Injectable({
  providedIn: 'root'
})
export class RRFirebaseService {
  user: firebase.User
  fs = firebase.firestore()


  constructor(private http: HttpClient) {
    const ustr = localStorage.getItem('user')
    if (ustr) {
      this.user = JSON.parse(ustr)
    }

    firebase.auth().onAuthStateChanged((user => {
      this.user = user
      this.saveUserToLocalStorage(user)
    }))

  }

  fbObserveChildAdded(path: string, callback: (a: firebase.database.DataSnapshot, b?: string) => any, cancel?: (a, b) => any) {
    return firebase.database().ref(path).on('child_added', callback, cancel)
  }

  fbObserveValueWhere(path: string, start: string, end: string, callback: (a: firebase.database.DataSnapshot, b?: string) => any, cancel?: (a, b) => any) {
    return firebase.database().ref(path)
      .startAt(start)
      .endAt(end)
      .on('value', callback, cancel)
  }

  fbObserveValue(path: string, callback: (a: firebase.database.DataSnapshot, b?: string) => any, cancel?: (a, b) => any) {
    console.log(path)
    return firebase.database().ref(path).on('value', callback, cancel)
  }


  fbRemoveValue(path: string): Promise<any> {
    return firebase.database().ref(path).remove()
  }

  fbSetValue(path: string, value: any): Promise<any> {
    return firebase.database().ref(path).set(value)
  }

  fbUpdateValue(path: string, value: any): Promise<any> {
    return firebase.database().ref(path).update(value)
  }


  getDownloadURL(path: string): Promise<string> {
    return firebase.storage().ref(path).getDownloadURL()
  }

  getValue(path: string): Promise<any> {
    return firebase.database().ref(path).once('value', (snap) => {
    }).then(a => {
      // console.log(path, a.val())
      return a.val()
    })
  }

  getValueWhere(path: string, start: string, end: string): Promise<any[]> {
    console.log('getValueWhere', path, start, end)
    return firebase.database().ref(path)
      .orderByKey()
      .startAt(start)
      .endAt(end)
      .once('value', (snap) => {
      }).then(a => {
        return a.val()
      })
  }

  getFSCollection(path: string): Promise<any[]> {
    return this.fs.collection(path).get()
      .then(d => {
        const rval = []

        d.forEach(r => {
          const row = r.data()
          row['id'] = r.id
          rval.push(row)
        })

        return rval
      })
  }

  getFSDocument(path: string): Promise<any> {
    return this.fs.doc(path).get()
      .then(data => {
        return data.data()
      })
      .catch(err => {
        throwError(err)
      })
  }



  login(email: string, password: string): Promise<firebase.User> {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then((uc) => {
        this.saveUserToLocalStorage(uc.user)
        return uc.user
      })
      .catch((err) => {
        console.log('RRFir', err)
        throw err.message
      })
  }

  logout(): Promise<any> {
    return firebase.auth().signOut()
      .then(() => {
        localStorage.removeItem('user')
      })
  }


  observeFSCollectionWhere(path: string, where: any): BehaviorSubject<any[]> {

    const obs = new BehaviorSubject<any[]>(null)

    console.log(path, where)

    this.fs.collection(path)
      .where(where.field, where.operator, where.value)
      .onSnapshot({
        next: (d) => {

          const rval = []
          d.forEach(da => {

            rval.push(da.data())
          })
          obs.next(rval)
        },
        error: (err) => {
          console.error(err)
        }
      })

    return obs

  }


  observeFSDocument(path: string): BehaviorSubject<any> {
    const obs = new BehaviorSubject<any>(null)

    console.log(path)

    this.fs.doc(path)
      .onSnapshot({
        next: (d) => {
          const data = d.data()
          console.log(data)
          obs.next(data)
        },
        error: (err) => {
          console.log(err)
        }
      })

    return obs
  }

  saveUserToLocalStorage(user: firebase.User) {
    localStorage.setItem('user', JSON.stringify(user))
  }


  shallowRead(path: string, db?: string): Promise<any> {
    let url = `https://${db || 'doxs-42fe8'}.firebaseio.com/${path}.json?shallow=true&auth=${keys[db] || 'BlMyE4fOR1ZMJdVmu2Ue28ElyzMh3RoXoko1R8zF'}`
    // console.log(`Reading: ${url}`)
    return new Promise((resolve, reject) => {
      const obs = this.http.get(url)
        .subscribe((res) => {
          // console.log(res)
          obs.unsubscribe()
          resolve(res)
        })
    })
  }

  fbWebRead(path: string, db?: string): Promise<any> {
    let url = `https://${db || 'doxs-42fe8'}.firebaseio.com/${path}.json?&auth=${keys[db] || 'BlMyE4fOR1ZMJdVmu2Ue28ElyzMh3RoXoko1R8zF'}`
    // console.log(`Reading: ${url}`)
    return new Promise((resolve, reject) => {
      const obs = this.http.get(url)
        .subscribe((res) => {
          // console.log(res)
          obs.unsubscribe()
          resolve(res)
        })
    })
  }

  idxSQL(client: string, table: string, fields: string, where?: string): Promise<any> {

    if (client.indexOf('firma-ph') !== -1) {
      let url = `https://id.e-firma.menariniapac.com/api/doSQLph.php?client=menarini&sql=SELECT ${fields} FROM ${table} ${where ? `where ${where}` : ''}`
      console.log(url)
      return new Promise((resolve, reject) => {
        const obs = this.http.get(url)
          .subscribe((res) => {
            // console.log(res)
            obs.unsubscribe()
            resolve(res)
          })
      })
    } else {
      // let url = `http://${client}.doccsonline.com/API/doSQL.php?sql=${sql}`
      let url = `https://t0ic05k7ak.execute-api.ap-southeast-1.amazonaws.com/default/lamdaDoSQL?table=${table}&fields=${fields}&client=${client}${where ? `&where=${where}` : ''}`
      console.log(url)
      return new Promise((resolve, reject) => {
        const obs = this.http.get(url)
          .subscribe((res) => {
            // console.log(res)
            obs.unsubscribe()
            resolve(res)
          })
      })
    }
  }
  // https://id.e-firma.menariniapac.com/api/doSQLph.php?client=menarini&sql=SELECT%20d.last_name%20%2B%20%27%2C%20%27%20%2B%20d.first_name%20as%20name%2C%20mobile_number%2C%20e_mail%2C%20psr.last_name%2B%27%2C%20%27%2Bpsr.first_name%20as%20psr_name%20%20FROM%20doctor%20d,%20psr%20where%20md_id%20%3D%20%27137984%27%20and%20territory_id%20%3D%20%27TD%27
  postSQL(client: string, sql: string) : Promise<any> {
    if (client.indexOf('firma-ph') !== -1) {
      let url = `https://id.e-firma.menariniapac.com/api/doSQLph.php?client=menarini&sql=${sql}`
      console.log(url)
      return new Promise((resolve, reject) => {
        const obs = this.http.get(url)
          .subscribe((res) => {
            // console.log(res)
            obs.unsubscribe()
            resolve(res)
          })
      })
    } else {
      // let url = `http://${client}.doccsonline.com/API/doSQL.php?sql=${sql}`
      let url = `https://t0ic05k7ak.execute-api.ap-southeast-1.amazonaws.com/default/lamdaDoSQL?client=idoxs_${client}`
      console.log(url)
      return new Promise((resolve, reject) => {
        console.log('PostSQL', url, sql)
        const obs = this.http.post(url, sql)
          .subscribe((res) => {
            console.log('POST SQL RESULT', res)
            obs.unsubscribe()
            resolve(res)
          })
      })
    }
  }

  postMySQLEFirma(sql : string) : Promise<any>{
    let url = `https://id.e-firma.menariniapac.com/api/doMySqlph.php?client=menarini&sql=${sql}`
      console.log(url)
      return new Promise((resolve, reject) => {
        const obs = this.http.get(url)
          .subscribe((res) => {
            // console.log(res)
            // console.log(sql)
            obs.unsubscribe()
            resolve(res)
          })
      }).catch((err) => console.log(err))
  }

}
